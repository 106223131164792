<!-- 缴费项目绑定学生 -->
<template>
  <div>
    <el-dialog
      class="free-electric-bind-dialog"
      title="批量绑定宿舍"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="close"
      v-loading="loadData.loading"
      width="1300px"
    >
      <el-descriptions direction="vertical" :column="5" border>
        <el-descriptions-item label="批次号" :span="2">
          {{ title.batchName }}
        </el-descriptions-item>
        <el-descriptions-item :label="`赠送额度${title.type==='month'?'（度/每人）':'（度/每宿舍）'}`">
          {{ title.degreesNum }}
        </el-descriptions-item>
        <el-descriptions-item label="赠送方式">
          {{ $http.getParamName(title.type, $parent.$parent.freeTypeList) }}
        </el-descriptions-item>
        <el-descriptions-item label="赠送日期（年月）">
          {{ title.monthStr }}
        </el-descriptions-item>
      </el-descriptions>
      <div style="margin: 10px 20px 0;">
        <el-tabs v-model="dialogType">
          <el-tab-pane label="已绑定的宿舍" name="BindDormView" />
          <el-tab-pane label="批量绑定操作" name="unbindDormTree" />
        </el-tabs>
      </div>
      <!-- 获取了 缴费项目的id后才开始加载 -->
      <template v-if="id">
        <bind-dorm-view ref="bindDormRef" v-if="dialogType==='BindDormView'" :batch-id="id"
                        :charging-status-list="chargingStatusList"
                        :task-status-list="taskStatusList"
                        @on-close="close" />
        <unbind-dorm-tree ref="unBindDormRef" v-if="dialogType==='unbindDormTree'" :batch-id="id" @on-close="close" />
      </template>
    </el-dialog>
    <!--  批量赠送 确认弹窗  -->
    <confirm-bind-dialog ref="confirmDialogRef" :visible.sync="visibleConfirmDialog" v-if="visibleConfirmDialog"
                         :confirm-info="title"
                         @on-confirm="handleBatchSend" />
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import BindDormView from './bindDormView.vue'
import unbindDormTree from './unbindDormTree.vue'
import ConfirmBindDialog from '@/views/pages/electric-manage/free-electric/component/confirmBindDialog.vue'
import { sendFreeElectricByBatchIdApi } from '@/api/dorm/free-electric-api'

export default {
  name: 'freeElectricBindDialog',
  components: {
    ConfirmBindDialog,
    unbindDormTree,
    BindDormView
  },
  mixins: [dialog],
  props: {
    freeTypeList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dialogType: 'BindDormView',
      visibleConfirmDialog: false,
      chargingStatusList: [], // 赠送电费到账状态（table展示数据用）
      taskStatusList: [] // 赠送电费到账状态（queryInfo过滤数据用）
    }
  },
  async mounted () {
    await Promise.all([
      this.$http.getParamListByKey('chargingStatus', this, 'chargingStatusList'),
      this.$http.getParamListByKey('taskStatus', this, 'taskStatusList')
    ])
    this.$nextTick(() => {
      if (!this.id) {
        this.$message.error('系统出错,请稍后重试！')
        this.close()
      }
    })
  },
  methods: {
    async handleBatchSend () {
      this.loadData.loading = true
      try {
        await sendFreeElectricByBatchIdApi(this.id)
        this.$message.success('批量赠送流程已启动，赠送电量将在10~15分钟内送达！')
        this.close()
      } catch (e) {
        if (!e) {
          this.$message.error('请求失败，请重试！')
        }
      } finally {
        this.loadData.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.free-electric-bind-dialog {
  .el-dialog {
    margin-top: 20px !important;
    margin-bottom: 0 !important;

    .el-dialog__body {
      padding-top: 0;
    }
  }
}
</style>

<template>
  <div v-loading="loading" class="unbind-dorm-tree flex-column oh">
    <div class="unbind-dorm-tree-selector">
      <div class="flex unbind-dorm-tree-selector_header">
        <ElInput class="search-input" v-model="keyword" placeholder="搜索" clearable>
          <template #prefix>
            <span class="el-icon-search"></span>
          </template>
        </ElInput>
        <el-checkbox :disabled="!!visibleNoChecks||!!visibleOnlyChecks" class="ml-1" v-model="isCheckAll"
                     true-label="isCheckAll"
                     @change="handleCheckAll"
                     style="line-height: 40px;">
          <span
            style="vertical-align: -1px;">全选</span>
        </el-checkbox>
        <el-checkbox :disabled="!!keyword" class="ml-1" true-label="visibleNoChecks"
                     v-model="visibleNoChecks"
                     @change="handleShowChecks"
                     style="line-height: 40px;"><span
          style="vertical-align: -1px;">只显示未勾选</span>
        </el-checkbox>
        <el-checkbox :disabled="!!keyword" class="ml-1" true-label="visibleOnlyChecks"
                     v-model="visibleOnlyChecks"
                     @change="handleShowChecks"
                     style="line-height: 40px;"><span
          style="vertical-align: -1px;">只显示已勾选</span>
        </el-checkbox>
        <tool-tips-group tips-content="存在【搜索关键字】时，这三个过滤功能禁用。" class="mr-1 ml-1" />
      </div>
      <div class="oh" style="height: calc(100vh - 370px);">
        <el-scrollbar style="height: calc(100vh - 354px);">
          <el-tree
            ref="treeRef"
            :data="treeData"
            :props="treeProps"
            node-key="id"
            show-checkbox
            default-expand-all
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            @check="handleCheck" />
        </el-scrollbar>
      </div>
    </div>
    <div class="unbind-dorm-tree__footer flex-not-shrink">
      <el-button @click="$emit('on-close')">关闭</el-button>
      <el-button type="primary"
                 v-permission="['freeElectric:bind']"
                 @click="handleBindDorms">批量绑定宿舍
      </el-button>
    </div>
  </div>
</template>

<script>
import { treeDormTreeHasStuApi } from '@/api/dorm'
import { bindFreeElectricBatchApi } from '@/api/dorm/free-electric-api'
import dormTree from '@/vue/mixins/dorm-tree'

export default {
  name: 'unbindDormTree',
  mixins: [dormTree],
  props: {
    batchId: String // 缴费 项目 id
  },
  data () {
    return {
      formData: {
        batchId: '',
        roomIds: []
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.formData.batchId = this.batchId
      this.renderTree()
    })
  },
  methods: {
    async renderTree (campusId) {
      this.loading = true
      try {
        const res = await treeDormTreeHasStuApi({ dormitoryInfoId: campusId, stuFlag: 0 })
        this.treeData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    handleCampus (val) {
      if (val) {
        this.renderTree(val)
      }
    },
    // 批量绑定（只保存批次，批量赠送需要在page页面点击‘批量赠送’）
    handleBindDorms () {
      if (!this.formData.roomIds.length) {
        this.$message.error('请先选择宿舍！')
        return
      }
      this.$confirm(`<p class="confirm-message">绑定后的宿舍将无法与本批次解绑。是否确定把这 <b class="bg-primary">${this.formData.roomIds.length}个</b> 宿舍绑定到<b class="primary">${this.$parent.$parent.title.batchName}</b>中？<br/>（批量赠送需要在表格页点击一下【批量赠送】，才能开启赠送电流程）。</p>`, '批量绑定宿舍', {
          dangerouslyUseHTMLString: true
        }
      )
        .then(async () => {
          try {
            await bindFreeElectricBatchApi(this.formData)
            this.$message.success('批量绑定成功！')
            this.formData.roomIds = []
            this.$refs.treeRef.setCheckedKeys([])
          } catch (e) {
            if (!e) {
              this.$message.error('批量绑定失败，请重试！')
            }
          }
        })
    }
  }
}
</script>

<style lang="scss">
.unbind-dorm-tree {
  height: calc(100vh - 260px);

  .el-icon-search {
    line-height: 40px;
  }

  .unbind-dorm-tree__footer {
    padding: 10px 0;
    text-align: center;
  }

  .unbind-dorm-tree-selector {
    @include flex-column;
    border: 1px solid var(--border-light);

    .unbind-dorm-tree-selector_header {
      border-bottom: 1px solid var(--border-light);
    }

    .search-input {
      @include flex-1;
      border-right: 1px solid var(--border-light);

      .el-input__inner {
        border: 0;
      }

      .el-input__wrapper {
        box-shadow: none;
      }
    }

    .el-tree {
      > .el-tree-node {
        height: auto;
        overflow: hidden;

        > .el-tree-node__content {
          margin-top: var(--space-primary);
          margin-bottom: 6px;
          background: #f3f5fb;
        }

        > .el-tree-node__children {
          > .el-tree-node {
            height: auto;
            overflow: hidden;

            > .el-tree-node__children {
              width: 100%;
              @include flex;
              flex-direction: row; /* 指定主轴方向为水平 */
              flex-wrap: wrap; /* 设为多行换行 */

              > .el-tree-node {
                margin-left: 52px;
                width: 11%;
                margin-bottom: 1em;

                > .el-tree-node__content {
                  padding-left: 12px !important;
                }
              }

              .el-tree-node__expand-icon {
                display: none;
              }
            }
          }
        }

      }
    }

  }

}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"queryInfo":_vm.queryInfo,"tool-list":['keyword']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"赠送日期(年月)","label-width":"70"}},[_c('el-date-picker',{attrs:{"type":"month","size":"small","format":"yyyyMM","value-format":"yyyyMM"},model:{value:(_vm.queryInfo.month),callback:function ($$v) {_vm.$set(_vm.queryInfo, "month", $$v)},expression:"queryInfo.month"}})],1)],1),_c('div',{staticClass:"header-button flex"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['freeElectric:edit']),expression:"['freeElectric:edit']"}],staticClass:"ml-1",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v(" 添加批次 ")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"60","align":"center"}}),_c('el-table-column',{attrs:{"prop":"batchName","label":"批次名","min-width":"200"}}),_c('el-table-column',{attrs:{"label":"赠送额度","width":"160","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.degreesNum))]),_vm._v(" "),_c('span',{staticClass:"font-grey"},[_vm._v("度 / "+_vm._s(row.type === 'month' ? '每   人 ' : '每宿舍'))])]}}])}),_c('el-table-column',{attrs:{"prop":"monthStr","label":"赠送日期（年月）","width":"160","align":"center"}}),_c('el-table-column',{attrs:{"label":"赠送方式","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((_vm.freeTypeList),function(ref){
var paramValue = ref.paramValue;
var btnType = ref.btnType;
var paramName = ref.paramName;
return _c('el-tag',{directives:[{name:"show",rawName:"v-show",value:(row.type=== paramValue),expression:"row.type=== paramValue"}],key:paramValue,attrs:{"type":btnType}},[_vm._v(_vm._s(paramName)+" ")])})}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","min-width":"200"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['freeElectric:edit']),expression:"['freeElectric:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id, row)}}},[_vm._v(" 编辑批次名 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['freeElectric:bind']),expression:"['freeElectric:bind']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showSubDialog('visibleBindDormDialog','bindDormDialogRef',row.id, row)}}},[_vm._v("绑定宿舍 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['freeElectric:del']),expression:"['freeElectric:del']"}],attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.del(_vm.delFreeElectricBatchApi,row.id,("" + (row.batchName)))}}},[_vm._v("删除 ")])]}}])})],1),(_vm.visibleDialog)?_c('free-electric-batch-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog,"free-type-list":_vm.freeTypeList},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleBindDormDialog)?_c('free-electric-bind-dialog',{ref:"bindDormDialogRef",attrs:{"visible":_vm.visibleBindDormDialog,"free-type-list":_vm.freeTypeList},on:{"update:visible":function($event){_vm.visibleBindDormDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
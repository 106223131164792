/**
 * 宿舍树 mixins
 */

export default {
  data () {
    return {
      loading: false,
      keyword: '',
      visibleOnlyChecks: false, // 已勾选
      visibleNoChecks: false, // 未勾选
      isCheckAll: false, // 全选
      treeData: [],
      treeProps: {
        value: 'id',
        label: 'label',
        children: 'children'
      },
      rowCheckList: [] // 选中的宿舍
    }
  },
  watch: {
    keyword: function (newVal) {
      this.loading = true
      // 存在 搜索关键字时,禁用只显示已勾选功能
      this.isCheckAll = false
      if (newVal) {
        this.visibleNoChecks = false
        this.visibleOnlyChecks = false
      }
      this.$refs.treeRef.filter(newVal)
      this.loading = false
    }
  },
  methods: {
    // 只显示已勾选/未勾选
    handleShowChecks (val) {
      this.loading = true
      switch (val) {
        case 'visibleNoChecks':
          this.visibleOnlyChecks = false
          this.isCheckAll = false
          this.$refs.treeRef.filter(val)
          break
        case 'visibleOnlyChecks':
          this.visibleNoChecks = false
          this.isCheckAll = true
          this.$refs.treeRef.filter(val)
          break
        default:
          this.isCheckAll = false
          this.$refs.treeRef.filter('')
      }
      this.loading = false
    },
    // 搜索条过滤数据 宿舍的
    filterNode (value, data, node) {
      if (!value) {
        // 没有regionId的宿舍禁止选择
        if (data.nodeType && data.nodeType === 'dormitoryRoom' && !data.regionId) {
          data.disabled = true
          node.visible = false
        } else {
          if (data.disabled) {
            data.disabled = false
            node.visible = false
          }
        }
        return true
      } else {
        if (!data.nodeType || data.nodeType !== 'dormitoryRoom') {
          data.disabled = true
          // 设置visible可以让dom及时更新禁选状态
          node.visible = false
        }
      }
      // value =visibleOnlyChecks或者 visibleNoChecks 时, 过滤门锁数据
      if (value === 'visibleOnlyChecks') {
        return node.checked
      } else if (value === 'visibleNoChecks') {
        return !node.checked
      } else {
        return data.label.indexOf(value) !== -1
      }
    },
    //  宿舍树 获取选中的宿舍
    handleCheck (node, checkedNodes) {
      if (!node.checked) this.isCheckAll = false
      const _ids = []
      if (checkedNodes.checkedNodes.length) {
        // 获取选中的 宿舍id(regionId=厂家方的宿舍id)
        checkedNodes.checkedNodes.map(({
                                         regionId,
                                         id
                                       }) => {
          if (regionId) _ids.push(id)
        })
      }
      // 存放选中宿舍的id
      this.formData.roomIds = [...new Set(_ids)]
    },
    // 宿舍树全选
    handleCheckAll (val) {
      this.loading = true
      // 遍历获取所有显示的宿舍id
      const _ids = []
      this.$refs.treeRef.data.map(build => {
        const _node = this.$refs.treeRef.getNode(build.id)
        if (_node && _node.visible && _node.childNodes.length) {
          this.recursiveDorms(_node.childNodes, _ids)
        }
      })
      if (val) {
        this.formData.roomIds = [...new Set(this.formData.roomIds.concat(_ids))]
      } else {
        this.formData.roomIds = this.formData.roomIds.filter(_id => !_ids.includes(_id))
      }
      this.$refs.treeRef.setCheckedKeys(this.formData.roomIds)
      this.loading = false
    },
    // 宿舍树 递归获取 visible 状态的所有宿舍
    recursiveDorms (arr = [], result = []) {
      if (!arr.length) return
      arr.map(node => {
        if (node.visible) {
          if (node.data.regionId) {
            result.push(node.data.id)
            return result
          } else if (node.childNodes.length) {
            this.recursiveDorms(node.childNodes, result)
          }
        }
      })
    }
  }
}

<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        :queryInfo.sync="queryInfo"
        :tool-list="['keyword']"
        @on-search="renderTable(1)">
        <el-form-item label="赠送日期(年月)" label-width="70">
          <el-date-picker
            type="month"
            size="small"
            v-model="queryInfo.month"
            format="yyyyMM"
            value-format="yyyyMM" />
        </el-form-item>
      </view-search-form>
      <div class="header-button flex">
        <el-button v-permission="['freeElectric:edit']" class="ml-1" type="primary" size="small" @click="showDialog()">
          添加批次
        </el-button>
      </div>
    </template>
    <!--  body  -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="60" align="center" />
      <el-table-column prop="batchName" label="批次名" min-width="200" />
      <el-table-column label="赠送额度" width="160" align="right">
        <template v-slot="{row}">
          <b>{{ row.degreesNum }}</b> <span class="font-grey">度 / {{ row.type === 'month' ? '每&nbsp;&nbsp;&nbsp;人 ' : '每宿舍'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="monthStr" label="赠送日期（年月）" width="160" align="center" />
      <el-table-column label="赠送方式" width="120" align="center">
        <template v-slot="{row}">
          <el-tag v-for="{paramValue, btnType, paramName} in freeTypeList" :key="paramValue" :type="btnType"
                  v-show="row.type=== paramValue">{{ paramName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="200" />
      <el-table-column fixed="right" label="操作" width="280">
        <template v-slot="{row}">
          <el-button v-permission="['freeElectric:edit']" type="primary" size="mini" @click="showDialog(row.id, row)">
            编辑批次名
          </el-button>
          <el-button type="primary" size="mini" v-permission="['freeElectric:bind']"
                     @click="showSubDialog('visibleBindDormDialog','bindDormDialogRef',row.id, row)">绑定宿舍
          </el-button>
          <el-button v-permission="['freeElectric:del']" type="danger" size="mini"
                     @click="del(delFreeElectricBatchApi,row.id,`${row.batchName}`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--  添加/编辑批次  -->
    <free-electric-batch-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
                                :free-type-list="freeTypeList" @on-close="renderTable" />
    <!--  批量绑定  -->
    <free-electric-bind-dialog ref="bindDormDialogRef" :visible.sync="visibleBindDormDialog"
                               v-if="visibleBindDormDialog" :free-type-list="freeTypeList"/>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import FreeElectricBindDialog from '@/views/pages/electric-manage/free-electric/component/freeElectricBindDialog.vue'
import freeElectricBatchDialog from '@/views/pages/electric-manage/free-electric/component/freeElectricBatchDialog.vue'
import { delFreeElectricBatchApi, pageFreeElectricBatchApi } from '@/api/dorm/free-electric-api'

export default {
  name: 'freeElectric',
  components: {
    FreeElectricBindDialog,
    freeElectricBatchDialog
  },
  mixins: [tableView],
  data () {
    return {
      visibleBindDormDialog: false,
      queryInfo: {
        batchId: null, // 批次
        dormitoryId: null, // 宿舍
        floorNum: null, // 层数
        month: null, // 筛选月份
        status: null // 电表任务状态：0-等待执行 1-执行中 2-成功 3-充值不成功
      },
      freeTypeList: [ // 批次类型 list
        {
          paramValue: 'month',
          paramName: '按月赠送',
          btnType: 'info'
        },
        {
          paramValue: 'daily',
          paramName: '自定义赠送',
          btnType: 'primary'
        }
      ]
    }
  },
  methods: {
    delFreeElectricBatchApi, // 删除api
    async renderTable (pageNum) {
      await this.getTableData(pageFreeElectricBatchApi, pageNum)
    }
  }
}
</script>

<template>
  <el-dialog
    title="批量赠电确认"
    :visible="show"
    :append-to-body="false"
    :before-close="close"
    width="450px"
  >
    <p class="confirm-message">送电流程启动后，将无法撤回和取消。<br/>
      是否确定对批次
      <b class="success">{{ $parent.title.batchName }}</b> 中已绑定（但未执行赠电流程）的
      <b class="bg-success">{{ $parent.$refs.bindDormRef.unSendCount }}个</b>
      宿舍执行赠电流程？</p>
    <template #footer>
      <el-button @click="close">取消赠送</el-button>
      <el-button type="success" :disabled="num>0" @click="handleConfirm">确认赠送{{ num > 0 ? `(${num})` : ''
        }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'confirmBindDialog',
  mixins: [dialog],
  props: {
    confirmInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      num: 5
    }
  },
  mounted () {
    setInterval(() => {
      if (this.num > 0) this.num--
    }, 1000)
  },
  methods: {
    handleConfirm () {
      this.$emit('on-confirm')
      this.close()
    }
  }
}
</script>

<style scoped>

</style>

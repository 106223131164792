import request from '@/service/request'

/* 赠送电费 批次 page */
export const pageFreeElectricBatchApi = (params) => {
  return request.post('/stu/autoFreeCharging/batch/page', params)
}
/* 赠送电费 批次 list
* batchId {string} 批次id
* status {number} 状态，-1可用于获取未赠送的数据
*  */
export const listFreeElectricBatchApi = (params) => {
  return request.post('/stu/autoFreeCharging/list', params)
}

/* 赠送电费 批次 删除
* id { string } 批次id
* */
export const delFreeElectricBatchApi = (id) => {
  return request.post(`/stu/autoFreeCharging/batch/delete/${id}`)
}

/* 赠送电费 批次 保存 */
export const saveFreeElectricBatchApi = (params) => {
  return request.post('/stu/autoFreeCharging/batch/save', params)
}

/* 赠送电费 批量绑定宿舍
* batchId { string } 批次id
* roomIds {array} 宿舍数组
* */
export const bindFreeElectricBatchApi = (params) => {
  return request.post('/stu/autoFreeCharging/batch/bind', params)
}

/* 赠送电费 发送批量赠送请求
* id { string } 批次id
*/
export const sendFreeElectricByBatchIdApi = (id) => {
  return request.post(`/stu/autoFreeCharging/batch/send/${id}`)
}

/* 赠送电费 赠送记录 page */
export const pageFreeElectricRecordApi = (params) => {
  return request.post('/stu/autoFreeCharging/page', params)
}

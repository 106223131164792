<template>
  <div>
    <table-view
      class="bind-dorm-view"
      :total="total"
      :query-info.sync="queryInfo"
      v-loading="loading"
      @reload-table="renderTable">
      <template #header>
        <view-search-form
          v-if="dormBuildList.length"
          ref="searchFormRef"
          :query-info.sync="queryInfo"
          @on-search="renderTable(1)">
          <el-form-item label="宿舍楼" prop="dormitoryId" label-width="55px">
            <el-select
              size="small"
              v-model="queryInfo.dormitoryId"
              @change="changeBuild"
              clearable
              filterable>
              <el-option
                v-for="{dormitoryName,id} in dormBuildList"
                :label="dormitoryName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
          <el-form-item label="楼层" label-width="55px">
            <el-select
              size="small"
              v-model="queryInfo.floorNum"
              clearable
              filterable>
              <el-option
                v-for="num in floorList"
                :label="`第${num}层`"
                :value="num"
                :key="num" />
            </el-select>
          </el-form-item>
          <el-form-item label="到账状态" label-width="90px">
            <el-select
              size="small"
              v-model="queryInfo.status"
              clearable
              filterable>
              <el-option
                label="未赠送"
                :value="-1" />
              <el-option
                v-for="{paramValue, paramName} in chargingStatusList"
                :label="paramName"
                :value="paramValue"
                :key="paramValue" />
            </el-select>
          </el-form-item>
        </view-search-form>
      </template>
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 447px)" border stripe>
        <el-table-column type="index" label="ID" width="80" align="center" />
        <el-table-column prop="dormitoryName" label="宿舍楼" width="190" />
        <el-table-column label="楼层" width="100" align="center">
          <template v-slot="{row}">
            第{{ row.floorNum }}层
          </template>
        </el-table-column>
        <el-table-column prop="roomName" label="宿舍" min-width="120" />
        <el-table-column prop="peopleCount" label="赠送人数" width="80" align="center" />
        <el-table-column prop="degreesNum" label="赠送总额（度）" width="180" align="center" />
        <el-table-column label="到账状态" width="220" align="center">
          <template v-slot="{row}">
            <p v-if="row.status||row.status===0">{{ $http.getParamName(row.status, chargingStatusList)
              }}
              <span v-if="row.taskStatus">
            {{ $http.getParamName(row.status, chargingStatusList) !== $http.getParamName(row.taskStatus, taskStatusList) ? '(' + $http.getParamName(row.taskStatus, taskStatusList) + ')' : ''
                }}</span>
            </p>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </table-view>
    <div class="font-center">
      <el-button class="mt-1 mb-1" @click="$emit('on-close')">关闭</el-button>
      <el-button v-permission="['freeElectric:send']" :disabled="!total|| !unSendCount" type="success"
                 @click="$parent.$parent.visibleConfirmDialog=true">
        批量赠送
      </el-button>
    </div>
  </div>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { listFreeElectricBatchApi, pageFreeElectricRecordApi } from '@/api/dorm/free-electric-api'

export default {
  name: 'bindDormView',
  mixins: [tableView],
  props: {
    batchId: String, // 缴费 项目 id
    taskStatusList: {
      type: Array,
      default: () => ([])
    },
    chargingStatusList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      queryInfo: { // queryInfo
        batchId: null,
        dormitoryId: null,
        endDate: null,
        floorNum: null,
        month: null,
        roomId: null,
        startDate: null,
        status: null,
        taskStatus: null
      },
      dormBuildList: [],
      floorList: 0,
      unSendCount: 0 // 已绑定但未赠送的宿舍宿舍数量
    }
  },
  mounted () {
    this.getDormBuildList()
  },
  methods: {
    // 获取已绑定宿舍列表
    async renderTable (pageNum) {
      if (!this.batchId) {
        return false
      } else {
        this.queryInfo.batchId = this.batchId
      }
      // 先获取本批次是否存在未赠送电的宿舍，否的话，赠送按钮不可点击
      try {
        const _res = await listFreeElectricBatchApi({
          batchId: this.batchId,
          status: -1
        })
        if (_res.data.length) {
          this.unSendCount = _res.data.length
        }
      } catch (e) {
        if (!e) {
          this.$message.error('获取失败，请重试！')
        }
      }
      await this.getTableData(pageFreeElectricRecordApi, pageNum)
    },
    // 获取宿舍楼
    async getDormBuildList () {
      this.floorList = 0
      try {
        const res = await this.$http.getDormBuildQuery({})
        this.dormBuildList = res.data
      } catch (e) {
      }
    },
    // 选择楼栋
    changeBuild (id) {
      this.floorList = 0
      this.queryInfo.floorNum = null
      if (id) {
        this.createFloor(id)
      }
    },
    // 生成楼层
    createFloor (parentId) {
      this.floorList = this.dormBuildList.filter(({ id }) => id === parentId)[0].floorAmount
    }
  }
}
</script>

<style lang="scss">
.bind-dorm-view {
  //height: calc(100vh - 220px);
  overflow: auto;
}
</style>

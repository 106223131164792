<!-- 自定义缴费批次 添加/编辑 -->
<template>
  <el-dialog
    class="free-electric-batch-dialog"
    :title="`${preTitle}赠送电费批次`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="close"
    width="520px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="180px">
      <el-form-item label="名称" prop="batchName">
        <el-input v-model.trim="formData.batchName" />
      </el-form-item>
      <template v-if="!this.id">
        <!-- 缴费类型 自定义项目 显示  -->
        <el-form-item label="赠送方式" prop="type">
          <el-radio-group v-model="formData.type" @change="handleType">
            <el-radio-button v-for="{paramValue,paramName} in freeTypeList" :key="paramValue" :label="paramValue">
              {{ paramName }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- 自定义赠送能添加月份，因为每个月只能新增一条赠送数据  -->
        <el-form-item label="赠送日期（年月）" :prop="formData.type==='month'?'monthStr':'-1'">
          <el-date-picker type="month"
                          :disabled="!!(formData.type==='daily')"
                          v-model="formData.monthStr"
                          style="width: 100%"
                          value-format="yyyyMM"
                          format="yyyyMM"
                          :picker-options="comptMonth" />
        </el-form-item>
        <el-form-item :label="formData.type==='daily'?'赠送额度（度/每宿舍）':'赠送额度（度/每人）'" prop="degreesNum">
          <el-input v-model.trim="formData.degreesNum" />
        </el-form-item>
      </template>
      <el-form-item prop="remarks" label="备注">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { saveFreeElectricBatchApi } from '@/api/dorm/free-electric-api'

export default {
  name: 'freeElectricBindDialog',
  mixins: [dialog],
  props: {
    freeTypeList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      PayTypeList: [],
      formData: {
        id: null,
        batchName: '',
        remarks: '',
        degreesNum: undefined, // 赠送度数
        monthStr: '',
        type: '' // month- 月份赠送，daily-自定义赠送
      },
      rules: {
        degreesNum: [
          {
            required: true,
            message: '请填写赠送额度（度/每人）',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorTwoDecimalPlacesObj,
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (this.formData.type === 'month' && Number(value) > 50) {
                  return callback(new Error('按月赠送额度不能设置高于50度!'))
                } else if (this.formData.type === 'daily' && Number(value) > 400) {
                  return callback(new Error('自定义赠送额度不能设置高于400度!'))
                } else if (Number(value) === 0) {
                  return callback(new Error('赠送额度不能为0！'))
                } else {
                  return callback()
                }
              }
            },
            trigger: 'blur'
          }
        ],
        batchName: {
          required: true,
          message: '请输入批次名',
          trigger: 'blur'
        },
        monthStr: {
          required: true,
          message: '请选择赠送年月',
          trigger: 'blur'
        },
        type: {
          required: true,
          message: '请选择赠送方式',
          trigger: 'blur'
        }
      }
    }
  },
  computed: {
    comptMonth () {
      return {
        disabledDate: time => {
          const _today = Date.now() - 8.64e7
          return time.getTime() < _today
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        ({
          batchName: this.formData.batchName,
          id: this.formData.id,
          remarks: this.formData.remarks
        } = this.title)
        this.setInitData()
      }
    })
  },
  methods: {
    handleType (val) {
      if (val === 'month' && !this.formData.degreesNum) {
        this.formData.degreesNum = '8'
        this.$refs.form.validateField('degreesNum')
      } else if (val === 'daily') {
        this.formData.monthStr = ''
      }
    },
    async handleSave () {
      if (this.id) {
        this.formData = {
          id: this.formData.id,
          batchName: this.formData.batchName,
          remarks: this.formData.remarks
        }
      }
      await this.save('赠送电费批次', saveFreeElectricBatchApi)
    }
  }
}
</script>
